import React from "react";
import Slider from "react-slick/lib/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { CgEditBlackPoint } from "react-icons/cg";
import Container from "../../../../utils/Container";
import { analyticsInsights, features } from "../../../../constants/all-products/features";

const FeatureCard = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    variableWidth: false,
    centerMode: true,
    centerPadding: "20px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };
  return (
    <Container>
      <section className="my-[4%]">
        <div className="flex">
          <div className="hidden md:block border-2 border-b-0 border-[#d9d9d9] min-h-[550px] relative w-1/12">
            <div className="text-[#c9c9c9] absolute bottom-1/2 left-0 right-0 origin-center -rotate-90 text-3xl font-extrabold">
              features
            </div>
          </div>
          <div className="w-11/12 hidden xl:block">
            <div className="flex ml-4 mb-4">
              {features.map((feature) => {
                return (
                  <div className=" border-2 border-[#d9d9d9] rounded-2xl ml-4 w-1/4 h-[41rem]" key={feature.title}>
                    <div className=" bg-primary-blue-dark py-4 rounded-t-2xl">
                      <p className="text-center text-white text-2xl font-semibold">{feature.title}</p>
                    </div>
                    <div className=" p-3 bg-[#f2f2f2]">
                      <p className=" text-primary-blue-dark font-bold pb-2">features</p>
                    </div>
                    <div>
                      <ul className="grid grid-rows-10 gap-5 items-center py-6 px-4">
                        {feature.lists.map((list) => {
                          return (
                            <li key={list} className="flex items-start">
                              <p className=" mt-[.25rem]">
                                <BsFillCheckCircleFill color="#0064bf" />
                              </p>
                              <p className="mx-1 w-10/12 font-bold">{list}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-11/12 mx-auto  block xl:hidden">
            <section className=" featureCard">
              <Slider {...settings}>
                {features.map((feature) => {
                  return (
                    <div
                      className=" border-2 border-[#d9d9d9] mx-1 rounded-2xl h-[50rem] md:h-[42rem] lg:h-[41rem]"
                      key={feature.title}
                    >
                      <div className=" bg-primary-blue-dark py-4 rounded-t-2xl">
                        <p className="text-center text-white text-3xl font-extrabold">{feature.title}</p>
                      </div>
                      <div className=" p-4 bg-[#f2f2f2]">
                        <p className=" text-primary-blue-dark text-lg font-bold">features</p>
                      </div>
                      <div>
                        <ul className="grid grid-rows-10 gap-8 items-center py-6 px-4">
                          {feature.lists.map((list) => {
                            return (
                              <li key={list} className="flex items-start flex-wrap">
                                <p className=" mt-[.25rem]">
                                  <BsFillCheckCircleFill color="#0064bf" />
                                </p>
                                <p className="mx-1 w-10/12 font-bold">{list}</p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </section>
          </div>
        </div>
        <div className="hidden md:flex border-2 rounded-b-2xl border-[#ccc]">
          <div className=" w-1/3 px-10 py-5">
            <p className="text-[#0764bf]">
              analytics <br />
              and insights
            </p>
          </div>
          <div className="w-2/3">
            <div className="grid grid-cols-2 px-10 py-5">
              {analyticsInsights.map((analyticsInsights) => {
                return (
                  <div key={analyticsInsights} className="flex items-center">
                    <p>
                      <CgEditBlackPoint color="#fcdb03" />
                    </p>
                    <p className=" text-xs p-1">{analyticsInsights}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default FeatureCard;
