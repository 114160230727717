import React from "react";
import Button from "../../../../components/Button";
import { Jira, SalesForce } from "../../../../images/index";
import Container from "../../../../utils/Container";

const CustomerSupport = () => {
  return (
    <Container>
      <div className=" my-8">
        <div className="bg-[#f2f2f2] border-2 border-[#d9d9d9] rounded-2xl">
          <div className=" p-4 md:p-12 flex flex-col justify-center items-center">
            <p className="text-center text-2xl md:text-base font-bold mb-6">
              for customization and multi language support
            </p>
            <div className=" bg-primary-yellow-dark rounded-xl p-5">
              <Button
                text="TALK TO US"
                link="https://entomo.co/contact/"
                style={{ backgroundColor: "#fcdb03", color: "#000" }}
              />
            </div>
          </div>
          <div className=" p-12 flex flex-col justify-center items-center">
            <p className=" opacity-50 text-2xl pb-4">connectors</p>
            <div className="flex flex-col md:flex-row items-center justify-around">
              <img src={Jira} alt="jira image" className=" w-48 h-10 object-contain" />
              <img src={SalesForce} alt="Sales force image" className="pl-11" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CustomerSupport;
