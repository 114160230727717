import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { IoIosArrowForward } from "react-icons/io";
import CarouselBrands from "../../../components/CarouselBrands";
import Banner from "../../../containers/Banner";
import CustomerSupport from "../../../containers/global/all-products/features/customerSupport";
import FeatureCard from "../../../containers/global/all-products/features/featureCard";
import Layout from "../../../containers/global/layout";
import { TestimonialList } from "../../../constants/all-products/features";
import Testimonial from "../../../containers/Testimonial";
import Button from "../../../components/Button";
import BreadCrumb from "../../../containers/BreadCrumb";
import {
  Adp,
  Audi,
  Bosch,
  Cgi,
  Generali,
  Harman,
  Johnson,
  Meric,
  Ntt,
  Ntuc,
  Porsche,
  Quest,
  Zurich,
} from "../../../images/index";
import Container from "../../../utils/Container";

const globalBrands = [Meric, Johnson, Generali, Porsche, Quest, Harman, Adp, Audi, Bosch, Cgi, Ntuc, Ntt, Zurich];
const breadCrumbItems = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "entomo",
    link: "",
  },
  {
    text: "features",
    link: "",
  },
];
const Features = ({ data }) => {
  const featureBanner = data?.featureBanner.nodes[0];
  const featureFooterBanner = data?.featureFooterBanner.nodes[0];
  return (
    <Layout>
      <Banner bgImg={featureBanner.desktopImage.gatsbyImageData} mobImg={featureBanner.mobileImage.gatsbyImageData}>
        <p className="absolute left-5 lg:left-20 xl:left-32 top-20 xl:top-32 text-3xl md:text-5xl font-extrabold">
          {featureBanner.title}
        </p>
      </Banner>
      <Container>
        <BreadCrumb breadCrumbItems={breadCrumbItems} style={{ margin: "4%" }} />
      </Container>
      <FeatureCard />
      <CustomerSupport />
      <CarouselBrands
        title={
          <div className=" font-medium">
            <p>
              powering <span>30 million+</span> users,
            </p>
            <p>1.4 million+ businesses, trusted by global marquee brands</p>
          </div>
        }
        globalBrands={globalBrands}
      />
      <Testimonial testimonialsList={TestimonialList} />
      <div className=" bg-primary-yellow-dark pt-[1.8%] pb-[1.2%]">
        <div className="grid grid-cols-1 sm:grid-cols-4 items-center w-11/12 mx-auto my-10 md:my-5 justify-center gap-10 md:gap-5 text-lg md:text-sm font-extrabold">
          <div>
            <p className="text-primary-blue-dark text-center">multilingual support</p>
            <p className="text-black text-center text-base md:text-sm font-medium md:font-normal">
              English, Chinese, Korean, Japanese,Bahasa, Thai, and Arabic
            </p>
          </div>
          <div>
            <p className="text-primary-blue-dark text-center">ISO 27001,SOC 2 certified</p>
          </div>
          <div>
            <p className="text-primary-blue-dark  text-center">onboarding,onsite rollout support</p>
          </div>
          <div>
            <p className="text-primary-blue-dark text-center">SSO/SAML supported</p>
          </div>
        </div>
      </div>
      <Banner
        bgImg={featureFooterBanner.desktopImage.gatsbyImageData}
        mobImg={featureFooterBanner.mobileImage.gatsbyImageData}
      >
        <div className="absolute top-5 text-center md:text-left lg:top-24 text-white md:w-1/3">
          <p className="text-3xl xl:text-5xl font-bold pb-5">{featureFooterBanner.title}</p>
          <p className="pb-5 text-base xl:text-2xl">{featureFooterBanner.subTitle}</p>
          <Button
            link="/request-demo"
            text="book a demo"
            sideImg={<IoIosArrowForward />}
            style={{ backgroundColor: "#ffda00", color: "#000" }}
          />
        </div>
      </Banner>
    </Layout>
  );
};

Features.propTypes = {
  data: PropTypes.object,
};

export default Features;

export const query = graphql`
  query {
    featureBanner: allContentfulBannerContent(filter: { heading: { eq: "Feature Page Banner " } }) {
      nodes {
        title
        subTitle
        desktopImage {
          gatsbyImageData
        }
        mobileImage {
          gatsbyImageData
        }
      }
    }
    featureFooterBanner: allContentfulBannerContent(filter: { heading: { eq: "Feature Page Footer Banner " } }) {
      nodes {
        title
        subTitle
        desktopImage {
          gatsbyImageData
        }
        mobileImage {
          gatsbyImageData
        }
      }
    }
  }
`;
