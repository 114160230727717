import { Nasri, Kornisiam, Adrian, Mochtar, Mukesh } from "../../images/index";

const features = [
  {
    title: "perform",
    lists: [
      "strategy map",
      "performance programs",
      "objective key results",
      "key performance indicator",
      "employee scorecard",
      "rating and calibrations",
    ],
  },
  {
    title: "engage",
    lists: ["employee hub", "check in/coach on", "360 feedback", "rewards", "kudos/ recognition", "polls/ surveys"],
  },
  {
    title: "act",
    lists: ["task management", "mission boards(for agile team)", "program", "lead management", "call in/ meet in"],
  },
  {
    title: "grow",
    lists: [
      "competencies for the future",
      "employee skill profiler",
      "skill assessment",
      "career pathways",
      "internal mobility",
      "learning recommendations",
      "talent cards",
      "succession planning",
      "enterprise talent maps and hotspots",
    ],
  },
];

const analyticsInsights = [
  "customized dashboards",
  "augmented analytics",
  "insights generator",
  "hyper-personalized nudges",
];

const TestimonialList = [
  {
    image: Mukesh,
    name: "Mukesh Dhawan",
    role: "CEO | Zurich Takaful Malaysia Bhd Zurich Insurance Company Ltd",
    body: "“We’re beating the market quarter on quarter. entomo has taken us to the next level of engagement with substantial increase in the number of active agents and revenue.”",
  },
  {
    image: Nasri,
    name: "Nasri Mohamed",
    role: "Ex. Vice President Telekom Malaysia",
    body: "“entomo enabled a 31% productivity increase for our 7000 plus front-liners with cost reduction of 14%, placing TM in the top quartile of telcos globally”",
  },
  {
    image: Kornisiam,
    name: "Kornsiam Nontarat",
    role: "Chief Agency Officer Generali",
    body: "“Agency Leaders and Agents are empowered with relevant insights that has taken both of their individual and team performance to a much higher level.  The dashboards give us a round the clock real-time knowledge of our performance, anytime, everywhere. Truly a next-generation performance management system.”",
  },
  {
    image: Adrian,
    name: "Adrian",
    role: "Activation & Technical Mastery PT Asuransi Jiwa Manulife Indonesia",
    body: "“entomo effectively helps us in seeing (Sales) district activities by providing updated production data during on-going discussions between leaders and agents. The CMP (Sales Process: Call, Meet, Present) makes it easy for us to monitor the Group’s activities.”",
  },
  {
    image: Mochtar,
    name: "Mochtar Suhadi",
    role: "Chief Organization & Development Officer Sinarmas Mining",
    body: "“The daily feed of key insights and intuitive dashboards at our fingertips has greatly improved our decision making process, our performance and made us more efficient.”",
  },
];

export { features, analyticsInsights, TestimonialList };
